export default {
  "dashBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "BlogArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog/Article"])},
  "offreEmploi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article(s) Publié(s)"])},
  "CreateArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un article"])},
  "CreateCategorie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une Catégorie"])},
  "Titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["titre de l'article"])},
  "creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["création"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "categorie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
  "auteur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur"])},
  "publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier un article"])},
  "modification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier un article"])},
  "aperçu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir un aperçu"])},
  "Publier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier"])},
  "soustitre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-titre"])},
  "Titrepublication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre de l’article"])},
  "Contenuarticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu de l’article"])},
  "Editer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer l’article"])},
  "aperçuArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu de l’article"])},
  "mettreAJour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])}
}