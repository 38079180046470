import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./auth";

const store = createStore({
  plugins: [createPersistedState()],
  state: {
    user: null,
    form: null,
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    form: (state) => state.form,
    setselectedImageUrl(state) {
      return state.selectedImageUrl;
    },
  },
  mutations: {
    setLogout(state) {
      state.user = null;
      state.form = null;
    },
    setUser(state, data) {
      state.user = data;
    },
    setForm(state, data) {
      state.form = data;
    },
    setselectedImageUrl(state, data) {
      state.selectedImageUrl = data;
    },
  },
  actions: {
    getLogout({ commit }, data) {
      commit("setLogout", data);
    },
    getUser({ commit }, data) {
      commit("setUser", data);
    },
    getForm({ commit }, data) {
      commit("setForm", data);
    },
  },
  modules: {
    auth,
  },
});

export default store;
