import { createRouter, createWebHistory } from "vue-router";
import pathLoader from "../plugins/loader";
import { auth } from "@/config/firebaseconfig.js";
import { onAuthStateChanged } from "firebase/auth";

const routes = [
  {
    path: "/",
    name: "Home",
    component: pathLoader("home"),
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "admindash",
        meta: { requiresAuth: true },
        component: pathLoader("admindash"),
      },
      {
        path: "/publication",
        name: "Publication",
        meta: { requiresAuth: true },
        component: pathLoader("publication"),
        props: true,
      },
      {
        path: "/publication-update/:id",
        name: "PublicationUpdate",
        meta: { requiresAuth: true },
        component: pathLoader("publicationUpdate"),
        props: true,
      },
      {
        path: "/view/:id",
        name: "View",
        meta: { requiresAuth: true },
        component: () => import("@/components/container/view/view.vue"),
        props: true,
      },
      {
        path: "/preview",
        name: "PreviewArticle",
        meta: { requiresAuth: true },
        component: pathLoader("preview"),
      },
      {
        path: "/category",
        name: "Category",
        meta: { requiresAuth: true },
        component: pathLoader("category"),
      },
    ],
  },
  {
    path: "/login",
    name: "LoginPage",
    meta: { requiresAuth: false },
    component: () => import("@/components/container/Connexion/LoginPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});
router.beforeEach((to, from, next) => {
  onAuthStateChanged(auth, (user) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      // Cette route nécessite une authentification
      if (user) {
        next(); // L'utilisateur est connecté, continuer vers la route demandée
      } else {
        next({
          path: "/login", // L'utilisateur n'est pas connecté, rediriger vers la page de login
        });
      }
    } else if (to.path === "/login" && user) {
      // Si l'utilisateur est connecté et tente d'accéder à la page de login
      next({
        path: "/", // Rediriger vers la page principale
      });
    } else {
      next(); // Continuer vers la route demandée
    }
  });
});

export default router;
