export default {
  "dashBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "BlogArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog/Article"])},
  "offreEmploi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" job offers"])},
  "CreateArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an article"])},
  "Titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["article title"])},
  "creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creation"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "categorie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "auteur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])},
  "publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish an article"])},
  "aperçu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See preview"])},
  "Publier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
  "soustitre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtitle"])},
  "Titrepublication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title of the article"])},
  "Contenuarticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article content"])},
  "Editer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit article"])},
  "aperçuArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article preview"])},
  "mettreAJour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])}
}