// src/config/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  getDocs,
  where,
  query,
  getDoc,
  setDoc,
} from "firebase/firestore";

// Configuration Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAbv1dO6YD8mlIToDhwdbGjpFAbEujbppQ",
  authDomain: "rema-da2f4.firebaseapp.com",
  databaseURL: "https://rema-da2f4.firebaseio.com",
  projectId: "rema-da2f4",
  storageBucket: "rema-da2f4.appspot.com",
  messagingSenderId: "822161756374",
  appId: "1:822161756374:web:99bef13fd6195ed414f977",
  measurementId: "G-Q5H76H6TJY",
};
// Initialiser Firebase
const app = initializeApp(firebaseConfig);

// Initialiser Firestore et Auth
const db = getFirestore();
const auth = getAuth(app);

export {
  db,
  collection,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  getDocs,
  getDoc,
  where,
  query,
  setDoc,
  auth,
};
