import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import install from "./install";
import i18n from "./i18n";
import { VDatePicker } from "v-calendar";
import VCalendar from "v-calendar";
import "v-calendar/style.css";
import { VueSvgIconPlugin } from "@yzfe/vue3-svgicon";
import "@yzfe/svgicon/lib/svgicon.css";
import CKEditor from "@ckeditor/ckeditor5-vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import ToggleButton from "vue-js-toggle-button";

createApp(App)
  .use(VueSvgIconPlugin, { tagName: "icon" })
  .use("QuillEditor", QuillEditor)
  .use(i18n)
  .use(store)
  .use(router)
  .use(install)
  .use(CKEditor)
  .use(VCalendar, { VDatePicker })
  .use(ToggleButton)

  .mount("#app");
