import {
  auth,
  db,
  collection,
  query,
  where,
  getDocs,
} from "@/config/firebaseconfig.js";
import { onAuthStateChanged } from "firebase/auth";

const state = {
  user: null,
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
};

const actions = {
  async fetchUser({ commit }) {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const q = query(collection(db, "admins"), where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          commit("setUser", doc.data());
        });
      } else {
        commit("setUser", null);
      }
    });
  },
};

const getters = {
  user: (state) => state.user,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
